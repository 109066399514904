import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface CheckboxItem {
  id: string;
  label: string;
  checked: boolean;
}

const checkboxesData: CheckboxItem[] = [
  { id: '1', label: 'Checkbox 1', checked: false },
  { id: '2', label: 'Checkbox 2', checked: false },
  { id: '3', label: 'Checkbox 3', checked: false },
];

const CheckboxesList: React.FC = () => {
  const [checkboxes, setCheckboxes] = useState<CheckboxItem[]>(checkboxesData);

  useEffect(() => {
    const savedCheckboxes = localStorage.getItem('checkboxes');
    if (savedCheckboxes) {
      setCheckboxes(JSON.parse(savedCheckboxes));
    }
  }, []);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(checkboxes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCheckboxes(items);
    localStorage.setItem('checkboxes', JSON.stringify(items));
  };

  const handleChange = (id: string) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.id === id) {
        return { ...checkbox, checked: !checkbox.checked };
      }
      return checkbox;
    });
    setCheckboxes(updatedCheckboxes);
    localStorage.setItem('checkboxes', JSON.stringify(updatedCheckboxes));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="checkboxes">
        {(provided) => (
          <FormGroup ref={provided.innerRef} {...provided.droppableProps}>
            {checkboxes.map((checkbox, index) => (
              <Draggable key={checkbox.id} draggableId={checkbox.id} index={index}>
                {(provided) => (
                  <FormControlLabel
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    control={<Checkbox checked={checkbox.checked} onChange={() => handleChange(checkbox.id)} />}
                    label={checkbox.label}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </FormGroup>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CheckboxesList;
