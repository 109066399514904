import React, { useState, useEffect } from 'react'
import { Checkbox, FormGroup, FormControlLabel, Button, IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Ingredients } from '../Interfaces'
import config from '../config';

export default function Home() {
  const [list, setList] = useState<Ingredients>(() => {
    const ls =  localStorage.getItem(`${config.ls_key}+ingredients`);

    if (ls && ls != 'undefined') {
        return JSON.parse(ls)
    } else {
        return []
    }        
  })

  useEffect(() => {
    localStorage.setItem(`${config.ls_key}+ingredients`, JSON.stringify(list))

    const delayDebounceFn = setTimeout(() => {
      sendUpdateToAPI()
    }, 1000)

    return () => clearTimeout(delayDebounceFn)

  }, [list])

  async function sendUpdateToAPI() {
    const last_update = new Date().toISOString();
    const payload = {
      ingredients: JSON.stringify(list),
      last_update: last_update
    };
    try {
      const response = await fetch('http://lukjanow.pl:8000/ingredients', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCheckboxStateChange = (id: string) => {
    const updatedCheckboxes = list.map((element) => {
      if (element.id === id) {
        return { ...element, checked: !element.checked };
      }
      return element;
    });
    setList(updatedCheckboxes);
  };

  return (
    <div>
      <Button variant="outlined" onClick={()=>{window.location.href='/manage'}}>Zarządzaj listą</Button>

      <FormGroup>
        {list.filter(element => element.to_buy == true).map((element, key) => (
          <>
            <div style={{width: "600px", float: "left"}}>
              <FormControlLabel control={<Checkbox checked={element.checked} onClick={() => handleCheckboxStateChange(element.id)} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} />} label={`${element.name} ${element.quantity || ""}`} />
            </div>
          </>
        ))}
      </FormGroup>
    </div>
  )
}


