import React, { useState, useEffect, useRef } from 'react'
import '../config'
import { v4 as uuidv4 } from 'uuid';
import { Ingredient, Ingredients } from '../Interfaces'
import { Checkbox, FormGroup, FormControlLabel, Button, ButtonGroup, IconButton, Box, FormControl, OutlinedInput, TextField } from '@mui/material';
import config from '../config';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';



const sampleList = [
    "Rzeżucha",
    "Rukola",
    "Banany",
    "Pomidory",
    "Cytryny",
    "Szynka Parmenska",
    "Prosciutto Cotto",
    "Parówki",
    "Ser Gouda",
    "Serek wiejski",
    "Twaróg",
    "Mozzarela",
    "Serek śmietankowy",
    "Jogurt nat mały",
    "Jogurt pitny",
    "Maslanka 1l",
    "Śmietana",
    "Masło",
    "Wafle ryż cien solone",
    "Chleb",
    "Liść laurowy",
    "Goździki",
    "Ziele angielskie",
    "Cynamon",
    "Herbata czarna",
    "Ciastka maślane",
    "Bebe",
    "Ziemniaki",
    "Brokuł",
    "Melon",
    "Ananas",
    "Jabłko",
    "Avokado?",
    "Mąka",
    "Cukier waniliowy",
    "Inka",
    "Zgrzewka mleka",
    "Jajka",
    "Truskawki mrożone",
    "Płyn do plukania",
    "Chusteczki wyciągane",
    "Ręcznik papierowy",
    "Tubki buraczane ",
    "Worki na śmieci 35l",
    "Worki na śmieci 60l",
    "Płyn do mycia naczyń",
    "Mydło w płynie",
    "Paluszki dla dzieci",
    "Płyn do spryskiwaczy"
]

export default function Manage() {
    const [list, setList] = useState<Ingredients>(() => {
        const ls = localStorage.getItem(`${config.ls_key}+ingredients`);

        if (ls && ls != 'undefined') {
            return JSON.parse(ls)
        } else {
            return []
        }
    })

    const [ingredientInput, setIngredientInput] = useState<any>()

    const ref:any = useRef('');

    useEffect(() => {
        localStorage.setItem(`${config.ls_key}+ingredients`, JSON.stringify(list))

    }, [list])

    const handleCheckboxStateChange = (id: string) => {
        const updatedCheckboxes = list.map((element) => {
            if (element.id === id) {
                return { ...element, to_buy: !element.to_buy };
            }
            return element;
        });
        setList(updatedCheckboxes);
    };


    function handleAddSampleIngredients() {
        if (window.confirm("Czy chcesz nadpisać CAŁĄ listę przykładowymi składnikami?\nTej czynności nie da się cofnąć.")) {
            let ingredients: Ingredients = []

            sampleList.map(element => {
                const newItem = {
                    id: uuidv4(),
                    name: element,
                }
                ingredients.push(newItem)
            })

            setList(ingredients)
        }
    }

    function handleClearList() {
        if (window.confirm("Czy chcesz skasować CAŁĄ listę składników?\nTej czynności nie da się cofnąć."))
            setList([])
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = Array.from(list);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setList(items);
    };

    function handleItemDelete(id: string) {
        const e: Ingredient | undefined = list.find(element => element.id == id);

        if (e !== undefined && window.confirm("Czy chcesz usunąć z listy element '" + e.name + "'?\nTej czynności nie da się cofnąć."))
            setList(list.filter(element => element.id !== id))
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => {
        const updatedCheckboxes = list.map((element) => {
            if (element.id === id) {
                return { ...element, quantity: event.target.value };
            }
            return element;
        });
        setList(updatedCheckboxes);
    };

    function handleAddIngredient() {
        const newItem = {
            id: uuidv4(),
            name: ingredientInput,
        }

        setList([...list, newItem])
        setIngredientInput("")
    }

    return (
        <div>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >

                <Button variant="outlined" onClick={() => { window.location.href = '/' }}>ZAKUPY</Button>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="checkboxes">
                        {(provided) => (
                            <FormGroup ref={provided.innerRef} {...provided.droppableProps}>
                                {list.map((checkbox, index) => (
                                    <Draggable key={checkbox.id} draggableId={checkbox.id} index={index}>
                                        {(provided) => (
                                            <div style={{ width: "600px", float: "left" }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <FormControlLabel control={<Checkbox checked={checkbox.to_buy} onClick={() => handleCheckboxStateChange(checkbox.id)} />} label={checkbox.name} />
                                                <FormControl sx={{ m: 1, width: '10ch' }} variant="outlined">
                                                    <OutlinedInput
                                                        id="outlined-adornment-weight"
                                                        aria-describedby="outlined-weight-helper-text"
                                                        inputProps={{
                                                            'aria-label': 'quantity',
                                                        }}
                                                        value={checkbox.quantity}
                                                        onChange={event => { handleInputChange(event, checkbox.id) }}
                                                    />
                                                </FormControl>
                                                <IconButton onClick={() => { handleItemDelete(checkbox.id) }}>
                                                    <DeleteForeverIcon fontSize="medium" />
                                                </IconButton>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </FormGroup>
                        )}
                    </Droppable>
                </DragDropContext>

                <div>
                    <TextField id="add-ingredient-input" label="Nowy składnik" type="text" value={ingredientInput} onChange={(e) => { setIngredientInput(e.target.value) }} />
                    <Button variant="contained" onClick={handleAddIngredient}>Dodaj</Button>
                </div>

                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    variant="contained"
                >
                    <Button variant="contained" onClick={handleAddSampleIngredients}>Wgraj przykładowe składniki</Button>
                    <Button variant="contained" onClick={handleClearList}>Wyczyść listę</Button>
                </ButtonGroup>
            </Box>
        </div>
    )
}
