import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Home from './Home';
import Manage from './Manage';
import CheckboxesList from './CheckboxesList';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/manage' element={<Manage />} />
        <Route path='/dragndrop' element={<CheckboxesList />} />
      </Routes>
    </BrowserRouter>
  );
}
